.ContentDiv {
    color: transparent;
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.ContentDiv:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
}