.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(34, 34, 34, 0.5);
    border-radius: 10px;
    padding: 20px; */
    margin: 0% 3%;
    width: 220px;
    max-width: 40%;
    color: white;
    font-family:"Obelix Pro" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}

.card a {
    width: 100%;
}

.bubble {
    border-radius: 50%;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.image {
    height: 100%;
    width: 100%;
}

.title {
    font-size: 1.5vmax;
    text-transform: uppercase;
    margin-bottom: 10px;
    width: 100%;
    height: 4vmax;
}

.socials {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    font-size: 40px;
}

.desc {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.socials a {
    display: flex;
    align-items: center;
    margin: 0px 10px;
}
