@font-face {font-family: "Obelix Pro";
  src: url("ed48d07fe0e3576377c848a466832226.eot"); /* IE9*/
  src: url("ed48d07fe0e3576377c848a466832226.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("ed48d07fe0e3576377c848a466832226.woff2") format("woff2"), /* chrome、firefox */
  url("ed48d07fe0e3576377c848a466832226.woff") format("woff"), /* chrome、firefox */
  url("ed48d07fe0e3576377c848a466832226.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("ed48d07fe0e3576377c848a466832226.svg#Obelix Pro") format("svg"); /* iOS 4.1- */
  font-display: swap;
}

* {
  box-sizing: border-box;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  scroll-behavior: smooth;
} 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: white;
  text-decoration: none;
}
