.container {
    height: 100%;
    width: 100%;
    padding: 30px;
    background: rgb(79,145,245);
    background: linear-gradient(180deg, rgba(79,145,245,1) 0%, rgba(93,243,255,1) 100%);
}

.outerBorder {
    height: 100%;
    width: 100%;
    padding: 60px;
    background-color: white;
}

.center {
    background: rgb(79,145,245);
    background: linear-gradient(180deg, rgba(79,145,245,1) 0%, rgba(93,243,255,1) 100%);
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.firstPage {
    min-height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.flexRow {
    width: 100%;
    padding: 0% 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.image {
    width: 40%;
    min-width: 300px;
    margin: 20px 0px;
}

.introduction {
    color: white;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    cursor: pointer;
    user-select: none;
    border: solid 2px rgb(32, 42, 131);
    background: rgb(31, 120, 253);
    border-radius: 10px;
    padding: 2px 10px;
}

.button:hover {
    background: rgb(61, 139, 255);
}

.downIcon {
    cursor: pointer;
    margin-top: auto;
    padding: 0px 15px 0px 15px;
    color: rgba(34, 34, 34, 0.5);
    border-bottom: solid 2px rgba(34, 34, 34, 0.5);
    margin-bottom: 5px;
}

.socials {
    display: flex;
    justify-content: space-around;
    width: 200px;
    padding: 10px;
    margin-bottom: 20px;
    background-color: rgba(34, 34, 34, 0.5);
    border-radius: 10px;
    font-size: 40px;
}

.socials a {
    text-decoration: none;
    color: white;
}

@media only screen and (max-width: 1270px) {
    .container {
        padding: 12px;
    }

    .outerBorder {
        padding: 24px;
    }
}

@media only screen and (max-width: 750px) {
    .introduction {
        width: 100%;
    }

    .downIcon {
        display: none;
    }
}