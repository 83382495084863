.container {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.menu {
    height: 6vw;
    width: 100%;
    background-color: #e2732d;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
}

.text {
    font-size: 3vw;
    margin-left: 10px;
    margin-right: 10px;
    text-transform: uppercase;
    color: white;
    font-family:"Obelix Pro" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}

.chevron {
    padding: 0px 10px;
    font-size: 5vw;
    margin-left: 10px;
    margin-right: 10px;
    text-transform: uppercase;
    color: white;
    font-family:"Obelix Pro" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}

.chevron svg {
    vertical-align: baseline;
}

.content {
    width: 95%;
    display: flex;
    background-color: rgba(15,15,15, 0.2);
    min-height: 200px;
    padding: 20px 20px 0px 20px;
}

.left {
    border-radius: 0px 50px 50px 0px;
    flex-direction: row;
}

.right {
    border-radius: 50px 0px 0px 50px;
    flex-direction: row-reverse;
}

@media only screen and (max-width: 1200px) {
	.container {
        width: 100%;
    }

    .content {
        width: 100%;
    }

    .chevron {
        display: none;
    }

    .left, .right {
        border-radius: 0px;
        flex-direction: row-reverse;
    }
}
