.container {
    width: 100%;
    align-self: flex-start;
    font-size: 20px;
    text-transform: uppercase;
    color: white;
    font-family: "Obelix Pro" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divider {
    border: solid 1px rgb(40, 141, 235);
    width: 200px;
    align-self: center;
    justify-content: center;
    margin-bottom: 30px;
}

.imageContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    text-decoration: none;
}

.imageContainer a {
    font-size: 12px;
    color: white;
    text-decoration: none;
}

.image {
    width: 100%;
    /* margin-bottom: 10px; */
    object-fit: contain;
}

.imagebox {
    margin: 10px;
    width: 40%;
    max-width: 600px;
}

.video {
    margin: 10px;
    width: 40%;
    max-width: 600px;
}

.youtube {
    margin: 10px;
    width: 40%;
    max-width: 600px;
}

.video video {
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .youtube, .video, .imagebox {
        width: 90%;
    }
  }